import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex-column gap-md" }
const _hoisted_2 = { class: "flex gap-sm" }
const _hoisted_3 = {
  key: 0,
  style: {"margin-right":"auto"}
}
const _hoisted_4 = {
  key: 1,
  style: {"margin-right":"auto"}
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex gap-sm" }
const _hoisted_9 = { class: "flex gap-sm" }
const _hoisted_10 = { class: "flex gap-sm" }
const _hoisted_11 = { class: "flex gap-sm" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { style: {"column-count":"2","column-gap":"var(--padding)","list-style-type":"none","padding":"0"} }
const _hoisted_14 = {
  key: 0,
  class: "flex gap-sm m-0"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "flex gap-sm m-0"
}
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_window = _resolveComponent("bp-window")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.modelValue.salutation || _ctx.modelValue.forename || _ctx.modelValue.surname)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.getFullSalutation(_ctx.modelValue)), 1))
        : (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.modelValue.email), 1)),
      _createVNode(_component_bp_button, {
        icon: ['far', 'pencil'],
        to: { name: 'admin.user.edit', params: { id: _ctx.modelValue._id } }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Edit user")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_bp_button, {
        color: "red",
        icon: ['far', 'trash-can'],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDeleteConfirmation(_ctx.modelValue._id)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Delete user")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Company')
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.companyClick ? 'a' : 'span'), {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.companyClick ? _ctx.companyClick() : undefined))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.customerStore.getById(_ctx.modelValue.company).company), 1)
          ]),
          _: 1
        }))
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Email')
    }, {
      default: _withCtx(() => [
        _createElementVNode("a", {
          href: `mailto:${_ctx.modelValue.email}`
        }, _toDisplayString(_ctx.modelValue.email), 9, _hoisted_5)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Phone')
    }, {
      default: _withCtx(() => [
        (!!_ctx.modelValue.phone)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: `tel:${_ctx.modelValue.phone.replace(/\(0\)/g, '').replace(/[^0-9+]/g, '')}`
            }, _toDisplayString(_ctx.modelValue.phone), 9, _hoisted_6))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, "–"))
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Registered')
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.localizeDatetime(_ctx.modelValue.password.timestamp)), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Authenticated')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_bp_icon, {
            icon: _ctx.modelValue.activated ? 'check' : 'xmark',
            color: _ctx.modelValue.activated ? 'green' : 'red'
          }, null, 8, ["icon", "color"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.activated ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Two-factor authentication')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_bp_icon, {
            icon: _ctx.modelValue.authenticator?.active ? 'check' : 'xmark',
            color: _ctx.modelValue.authenticator?.active ? 'green' : 'red'
          }, null, 8, ["icon", "color"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.authenticator?.active ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Admin')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_bp_icon, {
            icon: _ctx.modelValue.admin ? 'check' : 'xmark',
            color: _ctx.modelValue.admin ? 'green' : 'red'
          }, null, 8, ["icon", "color"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.admin ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
        ])
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_label, {
      seamless: "",
      label: _ctx.$gettext('Email notification')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.modelValue.email_notifications), ([type, emailNotification]) => {
          return (_openBlock(), _createBlock(_component_bp_fieldset, { key: type }, {
            legend: _withCtx(() => [
              _createElementVNode("p", _hoisted_11, [
                _createVNode(_component_bp_icon, {
                  icon: emailNotification.active ? 'check' : 'xmark',
                  color: emailNotification.active ? 'green' : 'red'
                }, null, 8, ["icon", "color"]),
                (type === 'download')
                  ? (_openBlock(), _createBlock(_component_translate, {
                      key: 0,
                      tag: "strong"
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("New software versions")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_translate, {
                      key: 1,
                      tag: "strong"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("New video uploads")
                      ])),
                      _: 1
                    })),
                (emailNotification.active)
                  ? (_openBlock(), _createElementBlock("em", {
                      key: 2,
                      innerHTML: _ctx.languageStore.getNameOf(emailNotification.language),
                      style: {"font-weight":"normal"}
                    }, null, 8, _hoisted_12))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionStore.getRootProducts().sort(_ctx.byKey('uuid')), (product) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: product }, [
                    (emailNotification.active && emailNotification.products.includes(product.uuid))
                      ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                          _createVNode(_component_bp_icon, {
                            icon: "check",
                            color: "green"
                          }),
                          _createElementVNode("span", {
                            innerHTML: _ctx.versionStore.getName(product)
                          }, null, 8, _hoisted_15)
                        ]))
                      : (_openBlock(), _createElementBlock("li", _hoisted_16, [
                          _createVNode(_component_bp_icon, {
                            icon: "xmark",
                            color: "red"
                          }),
                          _createElementVNode("span", {
                            style: {"color":"var(--theme-text-disabled)","text-decoration":"line-through"},
                            innerHTML: _ctx.versionStore.getName(product)
                          }, null, 8, _hoisted_17)
                        ]))
                  ], 64))
                }), 128))
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["label"]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-plural": "Do you really want to delete the following <strong>%{count} users</strong>?",
          "translate-n": _ctx.confirmUsers.length,
          "translate-params": { count: _ctx.confirmUsers.length }
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" Do you really want to delete the following "),
            _createElementVNode("strong", null, "user", -1),
            _createTextVNode("? ")
          ])),
          _: 1
        }, 8, ["translate-n", "translate-params"]),
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Company name")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Firstname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Lastname")
                ])),
                _: 1
              }),
              _createVNode(_component_translate, { tag: "th" }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode("Mail")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmUsers, (user) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: user._id
              }, [
                _createElementVNode("td", null, _toDisplayString(user.companyName), 1),
                _createElementVNode("td", null, _toDisplayString(user.forename), 1),
                _createElementVNode("td", null, _toDisplayString(user.surname), 1),
                _createElementVNode("td", null, _toDisplayString(user.email), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteUser(_ctx.confirmUsers.map(user => user._id))))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}