import { defineStore } from 'pinia';
import { $gettext } from 'vue-gettext';

interface State {
  cancelledTypes: CancelledType[];
}

/**
 * A cancelled type.
 */
export interface CancelledType {
  _id: string;
  name: string;
}

/**
 * The cancelled type store.
 */
export const useCancelledTypeStore = defineStore('cancelledType', {
  ///-------------------------------------------------------------------
  /// STATE
  ///-------------------------------------------------------------------
  state: (): State => {
    return {
      cancelledTypes: [
        {
          _id: 'mail',
          name: $gettext('Email'),
        },
        {
          _id: 'postal',
          name: $gettext('Postal'),
        },
      ]
    }
  },
  ///-------------------------------------------------------------------
  /// GETTERS
  ///-------------------------------------------------------------------
  getters: {
    /**
     * Searches for the cancelled type with the given ID and returns its name.
     * @param id The ID of the cancelled type.
     * @returns The name of the cancelled type.
     */
    getById: (state: State) => {
      return (id: string) => {
        return state.cancelledTypes.find(cancelledType => cancelledType._id === id);
      }
    },
  },
})