import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bp-customer-details__section bp-customer-details__section--company" }
const _hoisted_2 = { style: {"margin-right":"auto"} }
const _hoisted_3 = {
  key: 0,
  class: "bp-customer-details__section bp-customer-details__section--notes"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "bp-customer-details__section bp-customer-details__section--alert"
}
const _hoisted_6 = { class: "bp-customer-details__section bp-customer-details__section--general" }
const _hoisted_7 = { class: "mono" }
const _hoisted_8 = { class: "flex gap-sm" }
const _hoisted_9 = {
  key: 0,
  style: {"color":"var(--theme-text-secondary)"}
}
const _hoisted_10 = { class: "flex gap-sm" }
const _hoisted_11 = { class: "flex gap-sm" }
const _hoisted_12 = { class: "flex gap-sm" }
const _hoisted_13 = { class: "flex-column" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "bp-customer-details__section bp-customer-details__section--products" }
const _hoisted_17 = { class: "bp-customer-details__section bp-customer-details__section--contacts" }
const _hoisted_18 = { style: {"display":"flex","gap":"var(--padding)"} }
const _hoisted_19 = { style: {"margin-right":"auto"} }
const _hoisted_20 = { class: "bp-customer-details__section bp-customer-details__section--users" }
const _hoisted_21 = { style: {"display":"flex","gap":"var(--padding)"} }
const _hoisted_22 = { style: {"margin-right":"auto"} }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { key: 2 }
const _hoisted_26 = {
  key: 3,
  class: "bp-customer-overview__confirmation-hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_alert = _resolveComponent("bp-alert")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_customer_license_information = _resolveComponent("bp-customer-license-information")!
  const _component_bp_masonry_grid = _resolveComponent("bp-masonry-grid")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-customer-details', {
    'bp-customer-details--has-alert': _ctx.hasAlert,
    'bp-customer-details--has-notes': _ctx.modelValue.commentfield || _ctx.modelValue.specials,
  }])
  }, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.modelValue.company), 1),
      _createVNode(_component_bp_button, {
        icon: ['far', 'pencil'],
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editCustomer(_ctx.modelValue._id)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Edit customer")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_bp_button, {
        icon: ['far', 'user-secret'],
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.impersonateCustomer(_ctx.modelValue._id)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Impersonate customer")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_bp_button, {
        color: "red",
        icon: ['far', 'trash-can'],
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDeleteConfirmation(_ctx.modelValue._id)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Delete customer")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.modelValue.commentfield || _ctx.modelValue.specials)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("div", {
            class: "bp-customer-details__notes",
            innerHTML: [_ctx.modelValue.commentfield, _ctx.modelValue.specials].filter(el => !!el).join('<br><br>')
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasAlert)
      ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
          (_ctx.modelValue.supportCancel)
            ? (_openBlock(), _createBlock(_component_bp_alert, {
                key: 0,
                icon: ['far', 'triangle-exclamation'],
                color: "red"
              }, {
                default: _withCtx(() => [
                  (!!_ctx.modelValue.canceledTo && !!_ctx.modelValue.canceled_date && !!_ctx.modelValue.canceled_name && !!_ctx.modelValue.canceled_type)
                    ? (_openBlock(), _createBlock(_component_translate, {
                        key: 0,
                        "translate-params": {
            to: _ctx.localizeDate(_ctx.modelValue.canceledTo),
            date: _ctx.localizeDate(_ctx.modelValue.canceled_date),
            name: _ctx.getFullSalutation(_ctx.contactStore.getById(_ctx.modelValue.canceled_name)),
            type: _ctx.cancelledType?.name
          }
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("%{name} gave notice of cancellation on behalf of the customer on %{date} effective %{to} by %{type}.")
                        ])),
                        _: 1
                      }, 8, ["translate-params"]))
                    : (!!_ctx.modelValue.canceledTo)
                      ? (_openBlock(), _createBlock(_component_translate, {
                          key: 1,
                          "translate-params": { to: _ctx.localizeDate(_ctx.modelValue.canceledTo) }
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("The customer has cancelled as of %{to}.")
                          ])),
                          _: 1
                        }, 8, ["translate-params"]))
                      : (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("The customer has cancelled.")
                          ])),
                          _: 1
                        }))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_6, [
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Customer number')
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.modelValue.customerNumber), 1),
            _withDirectives(_createVNode(_component_bp_icon, {
              class: "cursor-pointer ml-sm",
              icon: ['far', 'copy'],
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyToClipboard(_ctx.modelValue.customerNumber)))
            }, null, 512), [
              [_directive_tooltip, _ctx.$gettext('Copy to clipboard')]
            ])
          ])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Support')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_bp_icon, {
              icon: _ctx.supportStatus.icon
            }, null, 8, ["icon"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.supportStatus.name), 1),
            (_ctx.supportStatus._id !== 'pilot_support')
              ? (_openBlock(), _createElementBlock("em", _hoisted_9, "(" + _toDisplayString(_ctx.localizeDate(_ctx.modelValue.supportFrom)) + " – " + _toDisplayString(_ctx.localizeDate(_ctx.modelValue.supportTo)) + ")", 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Pilot customer')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_bp_icon, {
              icon: _ctx.modelValue.pilot ? 'check' : 'xmark',
              color: _ctx.modelValue.pilot ? 'green' : 'red'
            }, null, 8, ["icon", "color"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.pilot ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
          ])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Data processing contract')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_bp_icon, {
              icon: _ctx.modelValue.dcpc ? 'check' : 'xmark',
              color: _ctx.modelValue.dcpc ? 'green' : 'red'
            }, null, 8, ["icon", "color"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.dcpc ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
          ])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Is reseller')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_bp_icon, {
              icon: _ctx.modelValue.reseller ? 'check' : 'xmark',
              color: _ctx.modelValue.reseller ? 'green' : 'red'
            }, null, 8, ["icon", "color"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.reseller ? _ctx.$gettext('Yes') : _ctx.$gettext('No')), 1)
          ])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_bp_label, {
        seamless: "",
        label: _ctx.$gettext('Address')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.street1), 1),
            (_ctx.modelValue.street2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.modelValue.street2), 1))
              : _createCommentVNode("", true),
            (_ctx.modelValue.street3)
              ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.modelValue.street3), 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.zip_code) + " " + _toDisplayString(_ctx.modelValue.city), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.country), 1)
          ])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _createElementVNode("section", _hoisted_16, [
      _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
      _createVNode(_component_translate, { tag: "h3" }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Products")
        ])),
        _: 1
      }),
      _createVNode(_component_bp_masonry_grid, { columns: 2 }, {
        default: _withCtx(() => [
          _createVNode(_component_bp_customer_license_information, { "model-value": _ctx.modelValue }, null, 8, ["model-value"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_17, [
      _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("h3", _hoisted_19, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("Contacts")
            ])),
            _: 1
          }),
          _cache[15] || (_cache[15] = _createTextVNode(" ")),
          _createElementVNode("span", null, "(" + _toDisplayString(_ctx.contacts.length) + ")", 1)
        ]),
        _createVNode(_component_bp_button, {
          color: "green",
          icon: ['far', 'address-book'],
          to: { name: 'admin.contact.new', query: { customer: _ctx.modelValue._id } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("New contact for customer")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createVNode(_component_bp_table, {
        data: _ctx.contacts,
        headers: _ctx.contactHeaders,
        selectable: false,
        unwrap: "",
        class: "bp-customer-details__table"
      }, {
        actions: _withCtx(({ item }) => [
          _withDirectives(_createVNode(_component_bp_icon, {
            icon: "magnifying-glass",
            onClick: ($event: any) => (_ctx.$emit('click-details', item))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Show details'))]
          ])
        ]),
        _: 1
      }, 8, ["data", "headers"])
    ]),
    _createElementVNode("section", _hoisted_20, [
      _cache[21] || (_cache[21] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("h3", _hoisted_22, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode("Registered users")
            ])),
            _: 1
          }),
          _cache[19] || (_cache[19] = _createTextVNode(" ")),
          _createElementVNode("span", null, "(" + _toDisplayString(_ctx.users.length) + ")", 1)
        ]),
        _createVNode(_component_bp_button, {
          color: "green",
          icon: ['far', 'user-check'],
          to: { name: 'admin.user.new', query: { customer: _ctx.modelValue._id } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Register new user for customer")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createVNode(_component_bp_table, {
        data: _ctx.users,
        headers: _ctx.userHeaders,
        selectable: false,
        unwrap: "",
        class: "bp-customer-details__table"
      }, {
        actions: _withCtx(({ item }) => [
          _withDirectives(_createVNode(_component_bp_icon, {
            icon: "magnifying-glass",
            onClick: ($event: any) => (_ctx.$emit('click-details', item))
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Show details'))]
          ])
        ]),
        _: 1
      }, 8, ["data", "headers"])
    ]),
    _createVNode(_component_bp_window, {
      type: "warning",
      modelValue: _ctx.confirmation,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.confirmation) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createTextVNode("Delete confirmation")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, {
          "translate-params": { list: _ctx.translatedUnitEnumeration({
            customers: _ctx.confirmDelete.customers.length,
            contacts: _ctx.confirmDelete.contacts.length,
            users: _ctx.confirmDelete.users.length,
          }) }
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode("Do you really want to delete "),
            _createElementVNode("strong", null, "%{list}", -1),
            _createTextVNode("?")
          ])),
          _: 1
        }, 8, ["translate-params"]),
        (_ctx.confirmDelete.customers.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_23, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode("Company")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.customers, (customer) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: customer._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(customer.company), 1)
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.contacts.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_24, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Company name")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode("Firstname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode("Lastname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createTextVNode("Mail")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.contacts, (contact) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: contact._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(contact.companyName), 1),
                    _createElementVNode("td", null, _toDisplayString(contact.forename), 1),
                    _createElementVNode("td", null, _toDisplayString(contact.surname), 1),
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString(contact.email), 1),
                      _cache[29] || (_cache[29] = _createElementVNode("span", { class: "bp-customer-overview__confirmation-hint" }, " *", -1))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.confirmDelete.users.length > 0)
          ? (_openBlock(), _createElementBlock("table", _hoisted_25, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                      _createTextVNode("Company name")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[31] || (_cache[31] = [
                      _createTextVNode("Firstname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                      _createTextVNode("Lastname")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_translate, { tag: "th" }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode("Mail")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.confirmDelete.users, (user) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: user._id
                  }, [
                    _createElementVNode("td", null, _toDisplayString(user.companyName), 1),
                    _createElementVNode("td", null, _toDisplayString(user.forename), 1),
                    _createElementVNode("td", null, _toDisplayString(user.surname), 1),
                    _createElementVNode("td", null, [
                      _createTextVNode(_toDisplayString(user.email), 1),
                      _cache[34] || (_cache[34] = _createElementVNode("span", { class: "bp-customer-overview__confirmation-hint" }, " *", -1))
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        ((_ctx.confirmDelete.contacts.length + _ctx.confirmDelete.users.length) > 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_26, [
              _cache[36] || (_cache[36] = _createTextVNode("* ")),
              _createVNode(_component_translate, {
                tag: "em",
                style: {"font-weight":"normal"},
                "translate-plural": "These <strong>%{list}</strong> are included in the selection and are therefore also deleted.",
                "translate-n": _ctx.confirmDelete.contacts.length + _ctx.confirmDelete.users.length,
                "translate-params": { list: _ctx.translatedUnitEnumeration({
            contacts: _ctx.confirmDelete.contacts.length,
            users: _ctx.confirmDelete.users.length,
          }, { includeCount: false }) }
              }, {
                default: _withCtx(() => _cache[35] || (_cache[35] = [
                  _createTextVNode("This "),
                  _createElementVNode("strong", null, "%{list}", -1),
                  _createTextVNode(" is included in the selection and is therefore also deleted.")
                ])),
                _: 1
              }, 8, ["translate-n", "translate-params"])
            ]))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_button, {
          icon: "check",
          color: "green",
          onClick: _ctx.deleteCustomer
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[37] || (_cache[37] = [
                _createTextVNode("Confirm")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_bp_button, {
          icon: "xmark",
          color: "red",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirmation = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[38] || (_cache[38] = [
                _createTextVNode("Cancel")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 2))
}