
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header, toBoolean, toDatetime, toMail, toTel } from '../table/BpTable';
import { byKey } from '@/utils/array';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { Contact, useContactStore } from '@/stores/contact';
import { copyToClipboard } from '@/utils/navigator';
import { currentLanguage, getTranslated } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { getFullSalutation } from '@/utils/user';
import { json } from '@sahnee/ajax';
import { localizeDate } from '@/utils/date';
import { reloadStores } from '@/utils/store';
import { SupportStatus, useSupportStatusStore } from '@/stores/supportStatus';
import { translatedUnitEnumeration } from '@/utils/string';
import { useCancelledTypeStore } from '@/stores/cancelledType';
import { useEditionStore } from '@/stores/edition';
import { useLanguageStore } from '@/stores/language';
import { User, useUserStore } from '@/stores/user';
import { useSalutationStore } from '@/stores/salutation';
import { useVersionStore } from '@/stores/version';
import BpCustomerLicenseInformationVue from './BpCustomerLicenseInformation.vue';
import BpToast from '../toast/BpToasts';
import router from '@/router';

export default defineComponent({
  name: 'bp-customer-details',
  components: {
    BpCustomerLicenseInformation: BpCustomerLicenseInformationVue,
  },
  props: {
    modelValue: {
      type: Object as PropType<Customer>,
      required: true,
    }
  },
  emits: [
    'click-details',
    'delete-customer',
  ],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    // ctx.emit('my-cool-event');

    const cancelledTypeStore = useCancelledTypeStore();
    const contactStore = useContactStore();
    const customerStore = useCustomerStore();
    const editionStore = useEditionStore(true);
    const languageStore = useLanguageStore();
    const salutationStore = useSalutationStore();
    const supportStatusStore = useSupportStatusStore();
    const userStore = useUserStore(true);
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// ALERTS
    ///-------------------------------------------------------------------

    const hasAlert = computed(() => props.modelValue.supportCancel);

    const cancelledType = computed(() => cancelledTypeStore.getById(props.modelValue.canceled_type))

    ///-------------------------------------------------------------------
    /// SUPPORT
    ///-------------------------------------------------------------------

    const supportStatus = computed(() => {
      const status = supportStatusStore.getByCustomer(props.modelValue);
      if (!status) {
        return { _id: 'undefined', icon: '', name: '' } as SupportStatus;
      }
      if (status._id === 'active') {
        return { _id: status._id, icon: status.icon, name: $gettext('Active') } as SupportStatus
      }
      return status;
    });

    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------
    
    function editCustomer(id: string) {
      router.push({
        name: 'admin.customer.edit',
        params: {
          id
        }
      });
    }

    async function impersonateCustomer(id: string) {
      const response = await json<{ success: boolean }>('/api/session-user/edit', { method: 'POST', json: { customerId: id, action: 'impersonate' } });
      if (response.success) {
        reloadStores(true);
        router.replace({
          name: 'home',
        });
      }
    }

    ///-------------------------------------------------------------------
    /// PRODUCTS
    ///-------------------------------------------------------------------

    const productTabs = computed(() => {
      const tabs = [];
      if (props.modelValue.editionProduct === 'bestzero') {
        tabs.push({ _id: 'bestzero', name: 'bestzero', icon: versionStore.getIcon('bestzero') });
      } else {
        if (props.modelValue.bestproxy || (props.modelValue.productselection && props.modelValue.productselection.includes('bestproxy'))) {
          tabs.push({ _id: 'bestproxy', name: 'bestproxy', icon: versionStore.getIcon('bestproxy') });
        }
        if (tabs.length === 0 || props.modelValue.bestinformed === undefined || props.modelValue.bestinformed !== false) {
          tabs.unshift({ _id: 'bestinformed', name: 'bestinformed', icon: versionStore.getIcon('bestinformed') });
        }
      }
      return tabs;
    });

    const activeTab = computed(() => productTabs.value[0]._id);

    ///-------------------------------------------------------------------
    /// EDITION
    ///-------------------------------------------------------------------

    const editionId = computed(() => customerStore.getBestinformedEdition(props.modelValue));

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------

    const editionApps = ref<string[]>([]);
    const editionExcludedApps = ref<string[]>([]);
    watch(() => editionStore.isLoading(), () => {
      if (!editionStore.loaded || editionStore.isLoading()) { return }
      const apps = editionStore.getById(props.modelValue.editionApps)?.apps;
      editionApps.value = Array.isArray(apps) ? apps.filter(app => app !== props.modelValue.editionApps) ?? [] : [];
      const excludedApps = editionStore.getById(props.modelValue.editionApps)?.excludedApps;
      editionExcludedApps.value = Array.isArray(excludedApps) ? excludedApps.filter(app => app !== props.modelValue.editionApps) ?? [] : [];
    }, { immediate: true })

    const isAppActive = (appId: string) => editionApps.value.includes(appId) || props.modelValue.apps.includes(appId);
    const isAppIncluded = (appId: string) => editionApps.value.includes(appId);
    const isAppExcluded = (appId: string) => editionExcludedApps.value.includes(appId);

    const appTooltip = computed(() => {
      const tooltips: string[] = [];
      const edition = editionStore.getNameOf(editionId.value);
      const apps = Array.isArray(props.modelValue.apps) ? props.modelValue.apps.filter(app => app !== props.modelValue.editionApps) : [];
      if (apps.length > 0 && apps.map(app => editionApps.value.includes(app)).includes(false)) {
        tooltips.push(`<p><span class="bp-customer-details__app--active">${$gettext('Bold')}</span>&ensp;–&ensp;${$gettext('The customer has a license for this app and it is not included in the %{edition}', { edition })}</p>`);
      }
      if (editionApps.value.length > 0) {
        tooltips.push(`<p><span class="bp-customer-details__app--included">${$gettext('Italic')}</span>&ensp;–&ensp;${$gettext('The app is included in the %{edition}', { edition })}</p>`)
      }
      if (editionExcludedApps.value.length > 0) {
        tooltips.push(`<p><span class="bp-customer-details__app--excluded">${$gettext('Strikethrough')}</span>&ensp;–&ensp;${$gettext('The app is excluded from the %{edition}', { edition })}</p>`)
      }
      return tooltips.join('');
    });

    ///-------------------------------------------------------------------
    /// CONTACTS
    ///-------------------------------------------------------------------

    const contacts = computed(() => contactStore.getByCustomerId(props.modelValue._id));

    const contactHeaders = ref<Header[]>([]);
    watch(() => [currentLanguage.value], () => {
      contactHeaders.value = [
        {
          _id: 'email',
          name: $gettext('Mail'),
          canCopy: true,
          width: '1.5fr',
          transform: toMail,
        },
        {
          _id: 'salutation',
          name: $gettext('Salutation'),
          filter: salutationStore.salutations.map(
            salutation => ({
              ...salutation,
              detect: (_contact: Contact, contactSalutation: Contact['salutation']) => salutation._id === contactSalutation
            })
          ),
          hidden: true,
        },
        {
          _id: 'forename',
          name: $gettext('Firstname'),
        },
        {
          _id: 'surname',
          name: $gettext('Lastname'),
        },
        {
          _id: 'language',
          name: $gettext('Language'),
          filter: [
            ...languageStore.languages.map(
              language => ({
                ...language,
                _id: '_' + language._id,
                detect: (_contact: Contact, contactLanguage: Contact['language']) => language._id === contactLanguage
              })
            ),
            {
              _id: 'unknown',
              name: $gettext('Unknown'),
              detect: (_contact: Contact, contactLanguage: Contact['language']) => !contactLanguage || Array.isArray(contactLanguage)
            }
          ],
          hidden: true,
          transform: (_contact: Contact, contactLanguage: Contact['language']) => languageStore.getNameOf(contactLanguage, 'md'),
        },
        {
          _id: 'position',
          name: $gettext('Position'),
          hidden: true,
        },
        {
          _id: 'phone',
          name: $gettext('Phone'),
          canCopy: true,
          transform: toTel,
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// USERS
    ///-------------------------------------------------------------------

    const users = computed(() => userStore.getByCustomer(props.modelValue));

    const userHeaders = ref<Header[]>([]);
    watch(() => [currentLanguage.value], () => {
      userHeaders.value = [
        {
          _id: 'activated',
          name: $gettext('Authenticated'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, activated: User['activated']) => activated,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, activated: User['activated']) => !activated,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: toBoolean,
        },
        {
          _id: 'email',
          name: $gettext('Mail'),
          canCopy: true,
          width: '1.5fr',
          transform: toMail,
        },
        {
          _id: 'forename',
          name: $gettext('Firstname'),
        },
        {
          _id: 'surname',
          name: $gettext('Lastname'),
        },
        {
          _id: 'phone',
          name: $gettext('Phone'),
          canCopy: true,
          transform: toTel,
        },
        {
          _id: 'password',
          name: $gettext('Registered'),
          transform: (user: User, password: User['password']) => toDatetime(user, password.timestamp),
        },
        {
          _id: 'authenticator.active',
          name: $gettext('2FA'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (user: User) => !!user.authenticator?.active,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (user: User) => !user.authenticator?.active,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: (user: User) => !!user.authenticator?.active,
        },
        {
          _id: 'email_notifications',
          name: $gettext('Email notification'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, email_notifications: User['email_notifications']) => Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true),
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, email_notifications: User['email_notifications']) => !Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true),
            }
          ],
          tooltip: (_user: User, email_notifications: User['email_notifications']) => {
            return Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true)
              ? Object.entries(email_notifications)
              .map(([type, emailNotification]) => `
                <p class="flex gap-sm">
                  <strong>${type === 'download' ? $gettext('New software versions') : $gettext('New video uploads')}</strong>
                  ${emailNotification.active ? `<em>${languageStore.getNameOf(emailNotification.language)}</em>` : ''}
                </p>
                <ul style="column-count: 2; column-gap: var(--padding); list-style-type: none; padding: 0;">
                  ${versionStore.getRootProducts().sort(byKey('uuid')).map(product => emailNotification.active && emailNotification.products.includes(product.uuid)
                    ? `<li class="flex gap-sm"><span style="color: var(--color-green-600); flex-shrink: 0; text-align: center; width: 1.25em;">&checkmark;</span><span>${versionStore.getName(product)}</span></li>`
                    : `<li class="flex gap-sm"><span style="color: var(--color-red-600); flex-shrink: 0; text-align: center; width: 1.25em;">&cross;</span><span style="color: var(--theme-text-disabled); text-decoration: line-through;">${versionStore.getName(product)}</span></li>`
                  ).join('')}
                </ul>
              `)
              .join('')
            : undefined;
          },
          notSortable: true,
          width: '0.8fr',
          transform: (_user: User, email_notifications: User['email_notifications']) => {
            return Object.values(email_notifications).map(emailNotification => emailNotification.active).includes(true);
          },
        },
        {
          _id: 'admin',
          name: $gettext('Admin'),
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'check', color: 'green' },
              detect: (_user: User, admin: User['admin']) => admin,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: 'xmark', color: 'red' },
              detect: (_user: User, admin: User['admin']) => !admin,
            }
          ],
          notSortable: true,
          width: '0.8fr',
          transform: toBoolean,
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      customers: Customer[],
      contacts: Contact[],
      users: User[]
    }>({
      customers: [],
      contacts: [],
      users: []
    });

    function showDeleteConfirmation(id: string) {
      const customers = customerStore.getById(id);
      const contacts = contactStore.getByCustomerId(id);
      const users = userStore.getByCustomerId(id);
      confirmDelete.value.customers = [customers];
      confirmDelete.value.contacts = Array.isArray(contacts) ? contacts.sort(byKey('email')) : [contacts];
      confirmDelete.value.users = Array.isArray(users) ? users.sort(byKey('email')) : [users];
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    //list({customer: Customer[], contact: Contact[]}, opts)

    async function deleteCustomer() {
      ctx.emit('delete-customer');
      const customerIDs = confirmDelete.value.customers.map(customer => customer._id);
      const contactIDs = confirmDelete.value.contacts.map(contact => contact._id);
      const userIDs = confirmDelete.value.users.map(user => user._id);
      const response = await customerStore.delete(customerIDs);

      if (response?.success) {
        const customers = response.data?.filter(doc => doc.doc_type === 'customer') || [];
        const contacts = response.data?.filter(doc => doc.doc_type === 'contact') || [];
        const users = response.data?.filter(doc => doc.doc_type === 'new_contact') || [];

        if (customers && contacts && users) {
          if (customers.length === customerIDs.length && contacts.length === contactIDs.length && users.length === userIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                customers: [customerIDs.length, customers.length],
                contacts: [contactIDs.length, contacts.length],
                users: [userIDs.length, users.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                customers.length + contacts.length + users.length,
                {
                  list: translatedUnitEnumeration({
                    customers: [customerIDs.length, customers.length],
                    contacts: [contactIDs.length, contacts.length],
                    users: [userIDs.length, users.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                customers: [customerIDs.length, customers.length],
                contacts: [contactIDs.length, contacts.length],
                users: [userIDs.length, users.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  customers.length + contacts.length + users.length,
                  {
                    list: translatedUnitEnumeration({
                      customers: [customerIDs.length, customers.length],
                      contacts: [contactIDs.length, contacts.length],
                      users: [userIDs.length, users.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      customers: [customerIDs.length, customers.length],
                      contacts: [contactIDs.length, contacts.length],
                      users: [userIDs.length, users.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            customers: customerIDs.length,
            contacts: contactIDs.length,
            users: userIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            customers: customerIDs.length,
            contacts: contactIDs.length,
            users: userIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        customers: [],
        contacts: [],
        users: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------
    
    return {
      actionTooltip,
      activeTab,
      appTooltip,
      cancelledType,
      confirmation,
      confirmDelete,
      contactHeaders,
      contacts,
      contactStore,
      copyToClipboard,
      customerStore,
      deleteCustomer,
      editCustomer,
      editionId,
      editionStore,
      getFullSalutation,
      getTranslated,
      hasAlert,
      impersonateCustomer,
      isAppActive,
      isAppExcluded,
      isAppIncluded,
      localizeDate,
      productTabs,
      showDeleteConfirmation,
      supportStatus,
      translatedUnitEnumeration,
      userHeaders,
      users,
      versionStore,
    }
  }
})
