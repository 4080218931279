import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "bp-dashboard-view__search" }
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"smaller","color":"var(--theme-text-disabled)"}
}
const _hoisted_3 = { class: "bp-dashboard-view__search-filter" }
const _hoisted_4 = { class: "bp-dashboard-view__grid" }
const _hoisted_5 = { class: "bp-dashboard-view__cordaware-logo-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  class: "flex gap-md",
  style: {"font-size":"2rem","justify-content":"center"}
}
const _hoisted_8 = {
  class: "flex gap-md",
  style: {"font-size":"2rem","justify-content":"center"}
}
const _hoisted_9 = {
  class: "flex gap-md",
  style: {"font-size":"2rem","justify-content":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_customer_details = _resolveComponent("bp-customer-details")!
  const _component_bp_contact_details = _resolveComponent("bp-contact-details")!
  const _component_bp_user_details = _resolveComponent("bp-user-details")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_table = _resolveComponent("bp-table")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("section", {
    class: "bp-dashboard-view",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_dashboard.jpg",
      icon: ['fad','gauge-high']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Dashboard")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      "fill-width": "",
      "fill-height": "",
      loading: _ctx.isLoading,
      "seamless-content": !!(_ctx.selected && _ctx.customer)
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createVNode(_component_bp_select, {
            large: _ctx.isLargeDesktop,
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selected) = $event)),
            data: _ctx.data,
            "search-property": _ctx.searchProperty,
            placeholder: _ctx.$gettext('Search…'),
            sortable: false,
            clearable: ""
          }, {
            option: _withCtx(({ option }) => [
              (!option._subheading)
                ? (_openBlock(), _createBlock(_component_bp_icon, {
                    key: 0,
                    icon: _ctx.icon(option)
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                style: _normalizeStyle(option._subheading ? { color: 'var(--theme-text-disabled)', fontVariant: 'all-petite-caps' } : (option.doc_type === 'contact' || option.doc_type === 'new_contact') ? { display: 'flex', flexDirection: 'column', margin: 'calc(-1 * var(--padding-sm)) 0' } : undefined)
              }, [
                _createElementVNode("span", null, _toDisplayString(option[_ctx.searchProperty(option)]), 1),
                ((option.doc_type === 'contact' || option.doc_type === 'new_contact') && option.companyName)
                  ? (_openBlock(), _createElementBlock("em", _hoisted_2, _toDisplayString(option.companyName), 1))
                  : _createCommentVNode("", true)
              ], 4)
            ]),
            text: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_bp_input, {
                  "model-value": _ctx.includedDocTypes.has('customer'),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateIncludedDocTypes('customer'))),
                  seamless: "",
                  type: "checkbox"
                }, {
                  text: _withCtx(() => [
                    _createVNode(_component_bp_icon, {
                      icon: "users",
                      class: "mr-sm"
                    }),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Customers")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model-value"]),
                _createVNode(_component_bp_input, {
                  "model-value": _ctx.includedDocTypes.has('contact'),
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateIncludedDocTypes('contact'))),
                  seamless: "",
                  type: "checkbox"
                }, {
                  text: _withCtx(() => [
                    _createVNode(_component_bp_icon, {
                      icon: "address-book",
                      class: "mr-sm"
                    }),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Contacts")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model-value"]),
                _createVNode(_component_bp_input, {
                  "model-value": _ctx.includedDocTypes.has('new_contact'),
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateIncludedDocTypes('new_contact'))),
                  seamless: "",
                  type: "checkbox"
                }, {
                  text: _withCtx(() => [
                    _createVNode(_component_bp_icon, {
                      icon: "user-check",
                      class: "mr-sm"
                    }),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode("Users")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model-value"])
              ])
            ]),
            _: 1
          }, 8, ["large", "modelValue", "data", "search-property", "placeholder"])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.selected && _ctx.customer)
          ? (_openBlock(), _createBlock(_component_bp_customer_details, {
              key: 0,
              modelValue: _ctx.customer,
              onClickDetails: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selected = $event._id)),
              onDeleteCustomer: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selected = ''))
            }, null, 8, ["modelValue"]))
          : (_ctx.selected && _ctx.contact)
            ? (_openBlock(), _createBlock(_component_bp_contact_details, {
                key: 1,
                modelValue: _ctx.contact,
                companyClick: () => { _ctx.selected = _ctx.contact?.company },
                onDeleteContact: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selected = ''))
              }, null, 8, ["modelValue", "companyClick"]))
            : (_ctx.selected && _ctx.user)
              ? (_openBlock(), _createBlock(_component_bp_user_details, {
                  key: 2,
                  modelValue: _ctx.user,
                  companyClick: () => { _ctx.selected = _ctx.user?.company },
                  onDeleteUser: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selected = ''))
                }, null, 8, ["modelValue", "companyClick"]))
              : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, [
            _createElementVNode("img", {
              class: "bp-dashboard-view__cordaware-logo",
              src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
            }, null, 8, _hoisted_6),
            _createVNode(_component_translate, { class: "bp-dashboard-view__cordaware-logo-prefix" }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [
                _createTextVNode("Welcome to the")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, { class: "bp-dashboard-view__cordaware-logo-suffix" }, {
              default: _withCtx(() => _cache[16] || (_cache[16] = [
                _createTextVNode("Customer portal")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("Admin area")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_bp_card, { "seamless-content": "" }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex-column gap-sm cursor-pointer",
                style: {"width":"100%"},
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.router.push({ name: 'admin.customer.overview' })))
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_bp_icon, { icon: "users" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.customerStore.customers.length), 1)
                ]),
                _createVNode(_component_translate, { style: {"margin":"0 auto"} }, {
                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                    _createTextVNode("Customers")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_bp_button, {
                class: "bp-dashboard-view__button",
                icon: "plus",
                color: "green",
                to: { name: 'admin.customer.new' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("New customer")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_bp_card, { "seamless-content": "" }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex-column gap-sm cursor-pointer",
                style: {"width":"100%"},
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.router.push({ name: 'admin.contact.overview' })))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_bp_icon, { icon: "address-book" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.contactStore.contacts.length), 1)
                ]),
                _createVNode(_component_translate, { style: {"margin":"0 auto"} }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Contacts")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_bp_button, {
                class: "bp-dashboard-view__button",
                icon: "plus",
                color: "green",
                to: { name: 'admin.contact.new' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("New contact")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_bp_card, { "seamless-content": "" }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex-column gap-sm cursor-pointer",
                style: {"width":"100%"},
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.router.push({ name: 'admin.user.overview' })))
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_bp_icon, { icon: "user-check" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.userStore.users.length), 1)
                ]),
                _createVNode(_component_translate, { style: {"margin":"0 auto"} }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("Registered users")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_bp_button, {
                class: "bp-dashboard-view__button",
                icon: "plus",
                color: "green",
                to: { name: 'admin.user.new' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode("Register new user")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_component_bp_table, {
                unwrap: "",
                key: _ctx.customerStore.loaded,
                "column-min-width": 3.3125,
                data: _ctx.userStore.users
              .sort((a, b) => new Date(a.password.timestamp).getTime() > new Date(b.password.timestamp).getTime() ? -1 : 1)
              .slice(0, 5),
                headers: [{ _id: 'email', name: _ctx.$gettext('Latest registered users') }],
                sortable: false,
                searchable: false,
                selectable: false,
                "default-sorting": ['password.timestamp', 'desc']
              }, {
                actions: _withCtx(({ item }) => [
                  _withDirectives(_createVNode(_component_bp_icon, {
                    icon: "users",
                    onClick: () => { _ctx.selected = item.company }
                  }, null, 8, ["onClick"]), [
                    [_directive_tooltip, _ctx.actionTooltip(_ctx.customerStore.getById(item.company)?.company)]
                  ]),
                  _withDirectives(_createVNode(_component_bp_icon, {
                    icon: "magnifying-glass",
                    onClick: () => { _ctx.selected = item._id }
                  }, null, 8, ["onClick"]), [
                    [_directive_tooltip, _ctx.actionTooltip(_ctx.$gettext('Show details'))]
                  ])
                ]),
                _: 1
              }, 8, ["data", "headers"]))
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, !_ctx.selected]
        ])
      ]),
      _: 1
    }, 8, ["loading", "seamless-content"])
  ], 4))
}