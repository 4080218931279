
import { $gettext, $ngettext } from 'vue-gettext';
import { byKey } from '@/utils/array';
import { Contact, useContactStore } from '@/stores/contact';
import { defineComponent, PropType, ref } from 'vue';
import { getFullSalutation } from '@/utils/user';
import { translatedUnitEnumeration } from '@/utils/string';
import { useCustomerStore } from '@/stores/customer';
import { useLanguageStore } from '@/stores/language';
import { useSalutationStore } from '@/stores/salutation';
import BpToast from '../toast/BpToasts';

export default defineComponent({
  name: 'bp-contact-details',
  props: {
    modelValue: {
      type: Object as PropType<Contact>,
      required: true,
    },
    companyClick: Function
  },
  emits: [
    'delete-contact',
  ],
  setup(_props, ctx) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const contactStore = useContactStore();
    const customerStore = useCustomerStore();
    const languageStore = useLanguageStore();
    const salutationStore = useSalutationStore();

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmContacts = ref<Contact[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      const contacts = contactStore.getById(ids);
      confirmContacts.value = Array.isArray(contacts) ? contacts.sort(byKey('companyName')) : [contacts];
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteContact(ids: string | string[]) {
      ctx.emit('delete-contact');
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      const response = await contactStore.delete(ids);
      if (response?.success) {
        const contacts = response.data;
        if (contacts) {
          if (contacts.length === ids.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                contacts: [ids.length, contacts.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                contacts.length,
                {
                  list: translatedUnitEnumeration({
                    contacts: [ids.length, contacts.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                contacts: [ids.length, contacts.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  contacts.length,
                  {
                    list: translatedUnitEnumeration({
                      contacts: [ids.length, contacts.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      contacts: [ids.length, contacts.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            contacts: ids.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            contacts: ids.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------
    
    return {
      confirmation,
      confirmContacts,
      customerStore,
      deleteContact,
      getFullSalutation,
      languageStore,
      salutationStore,
      showDeleteConfirmation,
    }
  }
})
