
import { $gettext } from 'vue-gettext';
import { actionTooltip } from '@/components/table/BpTable';
import { BaseDoc } from '@/components/virtual-scroller/BpVirtualScroller';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { byKey } from '@/utils/array';
import { computed, defineComponent, ref, watch } from 'vue';
import { PopoverElement } from '@/components/popover/BpPopoverMenu';
import { useContactStore } from '@/stores/contact';
import { useCustomerStore } from '@/stores/customer';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import BpContactDetailsVue from '@/components/contact/BpContactDetails.vue';
import BpCustomerDetailsVue from '@/components/customer/BpCustomerDetails.vue';
import BpUserDetailsVue from '@/components/user/BpUserDetails.vue';
import useCompactMode from '@/compositions/use-compact-mode';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'bp-dashboard-view',
  components: {
    BpCustomerDetails: BpCustomerDetailsVue,
    BpContactDetails: BpContactDetailsVue,
    BpUserDetails: BpUserDetailsVue,
  },
  setup() {
    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// STORES
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore(true);
    const contactStore = useContactStore();
    const userStore = useUserStore(true);

    const customer = computed(() => {
      if (!customerStore.loaded || customerStore.isLoading()) { return undefined }
      return customerStore.getById(selected.value || '');
    })

    const contact = computed(() => {
      if (!contactStore.loaded || contactStore.isLoading()) { return undefined }
      return contactStore.getById(selected.value || '');
    })

    const user = computed(() => {
      if (!userStore.loaded || userStore.isLoading()) { return undefined }
      return userStore.getById(selected.value || '');
    })


    const includedDocTypes = ref<Set<string>>(new Set(['customer', 'contact', 'new_contact']));
    const filterMenu = ref(false);

    const data = ref<BaseDoc[]>([]);
    watch(() => [customerStore.isLoading(), contactStore.isLoading(), userStore.isLoading()], updateData, { immediate: true });
    function updateData() {
      if (!customerStore.loaded || customerStore.isLoading() || !contactStore.loaded || contactStore.isLoading() || !userStore.loaded || userStore.isLoading()) { return }
      data.value = [
        ...[
          { _id: 'customers', company: $gettext('Customers'), doc_type: 'customer', _subheading: true },
          ...customerStore.customers.sort(byKey('company')),
          { _id: 'contacts', email: $gettext('Contacts'), doc_type: 'contact', _subheading: true },
          ...contactStore.contacts.sort(byKey('email')),
          { _id: 'registered_users', email: $gettext('Registered users'), doc_type: 'new_contact', _subheading: true },
          ...userStore.users.sort(byKey('email')),
        ].filter(item => Array.from(includedDocTypes.value).includes(item.doc_type)),
        ...!includedDocTypes.value.has('customer') && customer.value ? [customer.value] : [],
        ...!includedDocTypes.value.has('contact') && contact.value ? [contact.value] : [],
        ...!includedDocTypes.value.has('new_contact') && user.value ? [user.value] : [],
      ];
    }

    function getById(id?: string) {
      if (!id) {
        return {};
      }
      return customerStore.getById(id) || contactStore.getById(id) || userStore.getById(id);
    }

    function updateIncludedDocTypes(docType: string) {
      if (!includedDocTypes.value.has(docType)) {
        includedDocTypes.value.add(docType)
      } else {
        includedDocTypes.value.delete(docType);
      }
      updateData();
    }

    const isLoading = computed(() => customerStore.isLoading() || contactStore.isLoading() || userStore.isLoading())

    const icon = (item: PopoverElement) => {
      switch (item.doc_type) {
        case 'customer': return 'users';
        case 'contact': return 'address-book';
        case 'new_contact': return 'user-check';
        default: return item.icon || 'question';
      }
    }

    const searchProperty = (item: PopoverElement) => {
      switch (item.doc_type) {
        case 'customer': return 'company';
        case 'contact': return 'email';
        case 'new_contact': return 'email';
        default: return '_id';
      }
    }

    const selected = ref<string>();

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const { current: darkMode } = useDarkMode();

    const style = computed(() => ({
      '--dashboard-heading-color': (darkMode && darkMode.value) ? 'var(--theme-text)' : 'var(--color-cw-blue)',
    }))

    ///-------------------------------------------------------------------
    /// RESPONSIVE
    ///-------------------------------------------------------------------

    const isLargeDesktop = useMedia(Breakpoint.MIN_LG);

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode()

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      compactMode,
      contact,
      contactStore,
      customer,
      customerStore,
      darkMode,
      data,
      filterMenu,
      getById,
      icon,
      includedDocTypes,
      isLoading,
      isLargeDesktop,
      router,
      searchProperty,
      selected,
      style,
      updateIncludedDocTypes,
      user,
      userStore,
    }
  }
})
